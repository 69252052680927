@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-24px) scale3d(.9, .9, .9);
    transform: translateY(-24px) scale3d(.9, .9, .9);
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-24px) scale3d(.9, .9, .9);
    transform: translateY(-24px) scale3d(.9, .9, .9);
  }

  50% {
    opacity: 1;
  }
}

.dropdown {
  position: absolute;
  background: white;
  min-width: 120px;
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-shadow: 0px 1px 8px -2px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 1px 8px -2px rgba(0,0,0,0.2);
  box-shadow: 0px 1px 8px -2px rgba(0,0,0,0.2);
  /*overflow-y: auto;*/
  /*max-height: calc(100vh - 300px);*/
  border: 1px solid rgba(0,0,0,0.1);
  z-index: 5;

  font-size: 14px;
  display: none;

  animation-name: zoomInDown;
  animation-duration: 150ms;
  animation-fill-mode: both;
}

.dropdown .button {
  display: none;
}

@media screen and (max-width: 600px) {
  .dropdown {
    width: 100% !important;
    margin: 0 !important;
    border-radius: 0;
    border: none;
    z-index: 10000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 40px 20px !important;
    animation: none;
    text-align: left;
  }

  .dropdown .checkboxItem {
    margin-bottom: 10px;
  }

  .dropdown .button {
    display: block;
    width: auto !important;
  }

  .buttonWrap {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.dropdown.open {
  display: flex;
}
