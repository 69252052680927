.contactForm {
  margin-top: 32px;
  max-width: none;
}

.contactForm .input {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.contactForm textarea {
  min-height: 50px;
  max-height: 500px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
}

.footer button {
  width: 90px;
}

.footer span {
  font-size: 13px;
  flex-grow: 1;
  text-align: right;
  margin-right: 16px;
  color: rgba(0,0,0,0.4) !important;
}

/*.error {
  color: rgb(191, 63, 49);
}*/

/*.success {
  color: rgb(14, 183, 96);
}*/
