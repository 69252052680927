.basicSearch {
  font-size: 14px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basicSearch strong {
  margin: 0px 8px;
}

.basicSearch select {
  font-weight: bold !important;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgb(166,166,166);
  padding: 3px 5px !important;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.basicSearch select:focus {
  outline-width: 2px;
}

.filterBy {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.filterBy button {
  margin-right: 12px;
}

.spoilersDropdown,
.categoriesDropdown {
  max-width: 800px;
  width: calc(100% - 90px);
  left: 0px;
  margin: 5px 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.spoilersDropdown .group {
  width: 210px;
}

.categoriesDropdown .group {
  width: 236px;
}

.spoilersDropdown .group label {
  width: 185px;
}

.note {
  width: 100%;
  padding-top: 15px;
}

.submitButton {
  margin-left: 12px;
  flex-grow: 1;
}

.querySummary {
  position: relative;
  width: 445px;
  font-size: 13px;
  color: rgba(0,0,0,0.4) !important;
  padding: 10px;
  padding-top: 35px;
  border-radius: 5px;
  border: 1px solid rgb(166,166,166);
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.querySummary label {
  display: inline-block;
  margin-right: 16px;
}

.clearFiltersButton {
  border: none;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 3;
  color: rgba(0,0,0,0.4) !important;
}

form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  /*position: relative;*/
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.form {
  width: 100%;
  max-width: 800px;
  position: relative;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .basicSearch {
    flex-wrap: wrap;
    text-align: center;
  }

  .basicSearch .input {
    max-width: 90vw;
    width: 340px;
    margin-top: 20px;
    margin-left: 0;
    box-sizing: border-box;
  }

  .basicSearch .submitButton {
    width: 340px !important;
    max-width: 90vw;
    box-sizing: border-box;
    margin-top: 15px;
    flex-grow: 0;
    margin-left: 0;
  }

  /*.filterBy button {
    margin-right: 0;
    width: 340px !important;
    max-width: 90%;
    flex-wrap: wrap;
  }*/

  .filterBy strong {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .dropdownWrap {
    width: 340px;
    margin-bottom: 15px;
  }

  .dropdownWrap button {
    margin-right: 0;
    width: 340px !important;
    max-width: 90vw;
    flex-wrap: wrap;
  }

  .querySummary {
    width: 340px;
    max-width: 90vw !important;
    margin-bottom: 40px;
  }
}
