.group {
  padding-top: 15px;
  padding-bottom: 15px;
}

.group:last-child {
  flex-grow: 1;
}

.firstItem {
  margin-bottom: 8px;
}

.checkboxItem {
  display: flex;
  /*align-items: center;*/
  flex-wrap: wrap;
}

.checkboxItem label {
  margin-top: 2px;
  margin-left: 5px;
  word-break: break-word;
  /*position: absolute;*/
}

.checkboxTitle {
  display: block;
  margin-bottom: 8px !important;
  margin-left: 3px !important;
  text-transform: uppercase;
  font-size: 12px;
}

/*.inline {
  display: flex;
  align-items: center;
}*/
