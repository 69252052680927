.resultsWrap {
  padding: 20px 0px;
  width: 85%;
  max-width: 800px;
  margin: 0 auto;
}

.resultsTotal {
  margin-bottom: 12px;
  font-size: 16px;
  font-style: italic;
}

.resultsError {
  text-align: center;
}

.result {
  padding: 15px 0px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  line-height: 18px;
  font-size: 15px;
}

.summary {
  margin-top: 10px;
  margin-bottom: 10px;
}

.result:last-child {
  border-bottom: none;
}

.resultHeader {
  margin-bottom: 8px;
}

.label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  margin-right: 8px;
}

.result a {
  color: rgba(0,0,0,0.85);
  -webkit-transition: color 0.1s ease-in;
  transition: color 0.1s ease-in;
}

.result a:hover {
  color: rgba(0,0,0,1);
}

.pageLink {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  font-size: 14px;
  border: none;
  box-shadow: 0 0 0 1px rgb(166,166,166);
  -moz-box-shadow: 0 0 0 1px rgb(166,166,166);
	-webkit-box-shadow: 0 0 0 1px rgb(166,166,166);
  border-radius: 5px;
  color: black;
  font-weight: bold;
  text-decoration: none;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  box-sizing: border-box;
  cursor: pointer;
  background: white;
}

.pageLink:active {
  background: rgba(0,0,0,0.08);
}

.pageLink:focus {
  outline-width: 2px;
}

.pageLink:disabled {
  opacity: 0.45;
  cursor: default;
}

.pageLink.active {
  color: black;
  box-shadow: 0 0 0 2px black;
  -moz-box-shadow: 0 0 0 2px black;
	-webkit-box-shadow: 0 0 0 2px black;
  font-weight: bold;
}

.pageLinks {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  margin-left: -5px;
  margin-bottom: -5px;
  flex-wrap: wrap !important;
  width: 100%;
}

.rightArrow, .leftArrow {
  font-size: 9px;
}

.leftArrow {
  transform: rotate(180deg);
}

.list {
  -webkit-transition: opacity 0.15s ease-in;
  transition: opacity 0.15s ease-in;
}

.searching {
  opacity: 0.3;
  pointer-events: none;
}

.foundOn {
  font-size: 14px;
  font-style: italic;
  margin-top: 5px;
}

.highlight {
  font-size: 13px;
  opacity: 0.75;
  margin-bottom: 0px;
}

.highlight strong {
  color: black;
}
