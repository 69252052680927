body {
  -webkit-overflow-scrolling: touch;
}

.app {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

/*.body {
  width: 70%;
}*/

.headerWrap {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.header {
  color: black;
}

.links {
  width: 100%;
  color: black;
  text-align: center;
}

.links a {
  text-decoration: none;
  margin: 0 8px;
  font-size: 15px;
  color: black;
  opacity: 0.75;
}

.links a:focus {
  color: black;
}

.links a:hover {
  color: black;
  opacity: 1;
}

.centered {
  width: 90%;
  margin: 0 auto;
  max-width: 530px;
  padding-bottom: 50px;
}

.paypal {
  width: 180px;
  margin: 16px auto;
}

/*.centered {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 90%;
  max-width: 530px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}*/
