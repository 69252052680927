.button {
  background: white;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid rgb(166,166,166);
  padding: 3px 5px;
  font-size: 12px;
  z-index: 10;
  color: black;
}

.button:active {
  background: rgba(0,0,0,0.05);
}

.button:focus {
  outline-width: 2px;
}

.greenButton {
  color: rgb(14, 183, 96);
  border: 1px solid rgb(14, 183, 96) !important;
}

.greenButton:disabled {
  border: 1px solid rgba(14, 183, 96, 0.5) !important;
  color: rgba(14, 183, 96, 0.5) !important;
}
