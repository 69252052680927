.input {
  font-size: 13px;
  padding: 5px 7px;
  width: 200px;
  border: 1px solid rgb(166,166,166);
  border-radius: 3px;
  margin-bottom: 0px;
  resize: vertical;
}

.input:focus {
  /*border: 2px solid rgba(0,0,0,0.2);*/
  outline: none;
}

.input::-webkit-input-placeholder {
  color: rgba(0,0,0,0.4) !important;
}
